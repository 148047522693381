import React, { useContext, useRef } from 'react';

// Import Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLinkedinIn,
  faFacebook,
  faSlack,
  faGithub
} from '@fortawesome/free-brands-svg-icons';

// Import AppContext
import AppContext from '../../context/AppContext';

/*
  This component renders the "contact" section
  of the website.
*/

export default function Contact(props) {

  // Set initial state
  // (no initial state)

  // Acquire data from context
  const {
    currentPosY,
    updateSectionDistances
  } = useContext(AppContext);

  // Build ref for this section
  const contactSection = useRef(null);

  // If currentPosY is available, determine total distance and report to sectionDistances
  if (currentPosY !== null && contactSection.current !== null) {
    const distanceFromTopOfWindow = contactSection.current.getBoundingClientRect().top;
    const totalDistance = currentPosY + distanceFromTopOfWindow;
    updateSectionDistances({
      contact: totalDistance
    });
  }

  return (
    <section id="contact" ref={contactSection}>
      <h2 className="center">
        Connect with us!
      </h2>
      <div className="headingDividerCenter">
        <span />
      </div>
      <div className="textLinks">
        <a href="tel:+16162586179">
          616.258.6179
        </a>
        <a href="mailto:contact@slothwerks.com">
          contact@slothwerks.com
        </a>
      </div>
      <div className="iconLinks">
        <a href="https://www.linkedin.com/in/slothwerks/" target="_blank" rel="noopener noreferrer" name="LinkedIn">
          <span className="icon">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </span>
        </a>
        <a href="https://www.facebook.com/slothwerks/" target="_blank" rel="noopener noreferrer" name="Facebook">
          <span className="icon">
            <FontAwesomeIcon icon={faFacebook} />
          </span>
        </a>
        <a href="https://github.com/slothwerks-studio" target="_blank" rel="noopener noreferrer" name="GitHub">
          <span className="icon">
            <FontAwesomeIcon icon={faGithub} />
          </span>
        </a>
        <a href="https://join.slack.com/t/slothwerks-studio/shared_invite/enQtNTE2MTU0NTkwMDAzLTEyNzM5MzFiMmQwZDAwNjBiYWViODc1NWVjYjFiMjljZWI5M2M2YzBjODZiOTY3OGRiYmZkZDE0ZTFkNDNjZGU" target="_blank" rel="noopener noreferrer" name="Slack">
          <span className="icon">
            <FontAwesomeIcon icon={faSlack} />
          </span>
        </a>  
      </div>
      <div className="creativeLinks">
        <span>
          Site design by&nbsp;
          <a href="https://www.amyhendersoncreative.com/" target="_blank" rel="noopener noreferrer">
            Amy Henderson
          </a>
        </span>
        <span>
          Logo design by&nbsp;
          <a href="https://www.linkedin.com/in/edwindanderson/" target="_blank" rel="noopener noreferrer">
            Edwin Anderson
          </a>
        </span>
      </div>
    </section>
  );
}
