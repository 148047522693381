import React from 'react';
  
  // Initialize context; we'll use this to send our refs down the tree
  // https://reactjs.org/docs/context.html

  const AppContext = React.createContext({
    windowDimensions: null,
    currentPosY: null,
    sectionDistances: {},
    updateSectionDistances: () => {}
  });

  export default AppContext;