import React from 'react';

/*
  This component renders the top "splash" section
  of the website.
*/

export default function Splash(props) {

  // Set initial state
  // (no initial state)

  return (
    <section id="splash">
      <div className="background1" />
      <div className="background2" />
      <div className="contentWrapper">
        <div className="textContainer">
          <span className="code">Code.</span>
          <span className="communication">Communication.</span>
          <span className="community">Community.</span>
        </div>
        <div className="imageContainer">
          <img 
            src={require('../../assets/images/logo-image-only.png')} 
            alt="SlothWerks Logo"
          />
        </div>
      </div> 
    </section>
  );
}
