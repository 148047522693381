import React from 'react';

// Import Font Awesome
// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCaretLeft
} from '@fortawesome/free-solid-svg-icons';

/*
  This component renders the "products" section
  of the website.
*/

export default function Products(props) {

  // Set initial state
  // (no initial state)

  return (
    <section id="products" className="sidePadding">

      <div className="headingWrapper">
        <h2>
          Our Products
        </h2>
        <p className="subHeading">
          Designed for people.  Built by SlothWerks.
        </p>
        <div className="productsDivider">
          <span>
            <FontAwesomeIcon icon={faCaretLeft} transform={{ rotate: -45 }} />
          </span>
        </div>
    </div>

      <div className="item">
        <h3>
          SlothWerks Tech Calendar
        </h3>
        <div className="content">
          <div className="imageContainer">
            <img 
              src={require('../../assets/images/tech-calendar-mockup.jpg')} 
              alt="SlothWerks Tech Calendar mockup"
            />
          </div>
          <div className="textContainer">
            <p>
              The tech community in Grand Rapids is small yet extremely active.  How might one keep track of meetups, workshops, and events within this community?
            </p>
            <p>
              The SlothWerks Tech Calendar offers a simple yet intuitive method of accessing these events using data sourced from the publicly-available <a href="https://calendar.google.com/calendar/embed?src=uhj6b9q35gtr5c60ml7utsid80%40group.calendar.google.com&ctz=America%2FNew_York" target="_blank" rel="noopener noreferrer">Google Calendar</a> of the same name. 
            </p>
            <div className="appButtonWrapper">
              <a 
                className="appButton"
                href="https://apps.apple.com/us/app/slothwerks-tech-calendar/id1498327914"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  alt="Get the SlothWerks Tech Calendar on the App Store" 
                  src={require("../../assets/images/apple-store-button.jpg")}
                />
              </a>
              <a 
                className="appButton"
                href="https://play.google.com/store/apps/details?id=com.techcalendar&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  alt="Get the SlothWerks Tech Calendar on Google Play" 
                  src={require("../../assets/images/google-play-button.jpg")}
                />
              </a>
            </div>
          </div>  
        </div>
      </div>
      
    </section>
  );
}
