import React from 'react';

import HeaderBarContent from './HeaderBarContent';

/*
  This component is a container for the header bar on mobile.
*/

export default function HeaderBarMobile(props) {

  // Set initial state
  // (no initial state)

  return (
    <div className="headerBarMobile">
      <HeaderBarContent />
    </div>
  );
}
