import React, { useState } from 'react';

// Import React Swipeable Views
// https://github.com/oliviertassinari/react-swipeable-views
import SwipeableViews from 'react-swipeable-views';

/*
  This component renders the "partners" section
  of the website.
*/

export default function Partners(props) {

  // Set initial state
  const [currentSlide, setCurrentSlide] = useState(0);

  // Build a handler for slide updates
  const handleSlideChange = (newIndex) => {
    setCurrentSlide(newIndex);
  }

  // Define partners

  const grwebdev = <div className="item" key="grwebdev">
    <div className="border">
      {/* <h3>
        GRWebDev
      </h3> */}
      <div className="content">
        <div className="imageContainer extraTopPaddingDesktop">
          <img
            src={require('../../assets/images/grwebdev-logo.png')} 
            alt="GRWebDev powered by LaFleur"
          />
        </div>
        <div className="textContainer">
          <p>
            Sloth has served as a member of the <a href="https://www.meetup.com/grwebdev/" target="_blank" rel="noopener noreferrer">Grand Rapids Web Development Group</a> planning committee since September 2018.  Through his leadership, this technical meetup group acquired a title sponsor (<a href="https://lafleur.marketing/" target="_blank" rel="noopener noreferrer">LaFleur Marketing</a>) and has hosted presentations on topics ranging from product testing to machine learning to digital security.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const grandcircus = <div className="item" key="grandcircus">
    <div className="border">
      {/* <h3>
        Grand Circus
      </h3> */}
      <div className="content">
        <div className="imageContainer extraTopPaddingDesktop">
          <img
            src={require('../../assets/images/grand-circus-logo.png')} 
            alt="Grand Circus"
          />
        </div>
        <div className="textContainer">
          <p>
            SlothWerks provides instruction for <a href="https://www.grandcircus.co/" target="_blank" rel="noopener noreferrer">Grand Circus</a>' virtual intro to coding <a href="https://www.grandcircus.co/workshops/" target="_blank" rel="noopener noreferrer">workshops</a>.  These workshops focus on introducing students to the basic concepts of front end development while building a fully functional web application using HTML, CSS, and JavaScript.  Sloth also serves as a mentor to Grand Circus bootcamp students, offering guidance on career paths and post-graduation activities.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const cfgwm = <div className="item" key="cfgwm">
    <div className="border">
      {/* <h3>
        Code for Good West Michigan
      </h3> */}
      <div className="content">
        <div className="imageContainer">
          <img
            src={require('../../assets/images/cfgwm-logo.png')} 
            alt="Code for Good West Michigan"
          />
        </div>
        <div className="textContainer">
          <p>
            Sloth has served as a volunteer coordinator for <a href="https://codeforgoodwm.org/" target="_blank" rel="noopener noreferrer">Code for Good West Michigan</a>'s Weekend for Good event since 2018.  He and his fellow coordinators assemble teams of creatives to complete technical projects for non-profit organizations in West Michigan.  SlothWerks also assisted with the launch of an <a href="https://volunteer.codeforgoodwm.org/" target="_blank" rel="noopener noreferrer">online volunteer portal</a> in 2022.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const partnerItems = [
    grwebdev,
    grandcircus,
    cfgwm
  ];

  return (
    <section id="partners">

      <div className="headingWrapper">
        <h3>
          Partner Highlights
        </h3>
        <div className="headingDividerCenter">
          <span />
        </div>
      </div>

      <div className="swipeableViewsWrapper">
        <SwipeableViews
          index={currentSlide}
          onChangeIndex={handleSlideChange}
        >
          { partnerItems }
        </SwipeableViews>
      </div>

      <div className="items">
        { partnerItems }
      </div>

      <div className="indicatorWrapper">
        <button 
          className={(currentSlide === 0) ? "indicator filled" : "indicator"} 
          onClick={() => handleSlideChange(0)}
          name="Slide 1"
        />
        <button 
          className={(currentSlide === 1) ? "indicator filled" : "indicator"}
          onClick={() => handleSlideChange(1)} 
          name="Slide 2"
        />
        <button
          className={(currentSlide === 2) ? "indicator filled" : "indicator"}
          onClick={() => handleSlideChange(2)} 
          name="Slide 3"
        />
      </div>
      
    </section>
  );
}
