import React from 'react';

/*
  This component renders the "about" section
  of the website.
*/

export default function About(props) {

  // Set initial state
  // (no initial state)

  return (
    <section id="about" className="sidePadding">
      <h2>
        Solutions designed for you.
      </h2>
      <div className="contentWrapper">
        <div className="textContainer">
          <p>
            Put our twenty years of technical experience to the test.  We're problem-solvers, using code as our toolset.  Sometimes all you need is a fresh perspective:  SlothWerks offers you a mirror in which you can see obstacles - and their possible solutions - in a new light.
          </p>
        </div>
        <div className="imageContainer">
          <img 
            src={require('../../assets/images/sloth-collaboration.jpg')} 
            alt="Sloth collaborating with fellow creatives"
          />
        </div>   
      </div>
    </section>
  );
}
