import React from 'react';

// Import Font Awesome
// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhoneAlt, 
  faEnvelope 
} from '@fortawesome/free-solid-svg-icons';

/*
  This component renders the header bar content at the top of the app.
*/

export default function HeaderBarContent(props) {

  // Set initial state
  // (no initial state)

  return (
    <div className="headerBarContent">
      <span className="headerBarText">
        <span className="sloth">
          Sloth
        </span>
        <span className="werks">
          Werks
        </span>
      </span>
      <div className="headerBarLinks">
        <a href="tel:+16162586179" className="phone" name="Phone">
          <span className="icon">
            <FontAwesomeIcon icon={faPhoneAlt} />
          </span>
        </a>
        <a href="mailto:contact@slothwerks.com" className="email" name="Email">
          <span className="icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
        </a>  
      </div>
    </div>
  );
}
