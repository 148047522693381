import React, { useState } from 'react';

// Import React Swipeable Views
// https://github.com/oliviertassinari/react-swipeable-views
import SwipeableViews from 'react-swipeable-views';

/*
  This component renders the "projects" section
  of the website.
*/

export default function Projects(props) {

  // Set initial state
  const [currentSlide, setCurrentSlide] = useState(0);

  // Build a handler for slide updates
  const handleSlideChange = (newIndex) => {
    setCurrentSlide(newIndex);
  }

  // Define projects

  const itea = <div className="item" key="itea">
    <div className="border">
      <h3>
        ITEA Mobile
      </h3>
      <div className="content">
        <div className="imageContainer">
          <img
            src={require('../../assets/images/itea-logo.png')} 
            alt="Illinois Truck Enforcement Association logo"
          />
        </div>
        <div className="textContainer">
          <p>
            SlothWerks partnered with <a href="https://www.authoritek.com/" target="_blank" rel="noopener noreferrer">Authoritek LLC</a> to create a mobile app for the members of the <a href="https://illinoistruckcops.org/" target="_blank" rel="noopener noreferrer">Illinois Truck Enforcement Association</a>, giving them access to ten years' worth of data, documents, and legal resources while out on the road.  The app saw a version 2 release in February 2022, now utilizing a custom authentication system and membership management platform.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const sweetspot = <div className="item" key="sweetspot">
    <div className="border">
      <h3>
        Sweetspot Farms
      </h3>
      <div className="content">
        <div className="imageContainer">
          <img
            src={require('../../assets/images/sweetspot-logo.png')} 
            alt="Sweetspot logo"
          />
        </div>
        <div className="textContainer">
          <p>
            <a href="https://sweetspotfarms.com/" target="_blank" rel="noopener noreferrer">Sweetspot Farms</a> wanted to take their existing CRM-based website to the next level with custom animations and other additional features.  SlothWerks collaborated with the Sweetspot design team to rebuild the site as a single page application, adding these animations as well as an interactive quiz, online store, and backend integration with Google Cloud Storage, Mailchimp, and more.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const digicard = <div className="item" key="digicard">
    <div className="border">
      <h3>
        Digicard Key Services
      </h3>
      <div className="content">
        <div className="imageContainer">
          <img
            src={require('../../assets/images/digicard-key-logo.png')} 
            alt="Digicard Key Services logo"
          />
        </div>
        <div className="textContainer">
          <p>
            <a href="https://digicardkey.com/" target="_blank" rel="noopener noreferrer">Digicard Key Services</a> provides ownership verification services for collectible items.  SlothWerks led a team of developers and designers to build the Digicard Key mobile app that allows users to retrieve item information from encrypted NFC tags, submit ownership claims, and build a historical record of claims to the <a href="https://www.vechain.org/" target="_blank" rel="noopener noreferrer">VeChain Thor</a> blockchain.
          </p>
        </div>  
      </div>
    </div>
  </div>

  const projectItems = [
    itea,
    sweetspot,
    digicard
  ];

  return (
    <section id="projects">

      <div className="headingWrapper">
        <h2>
          Code at SlothWerks
        </h2>
        <span className="subHeading italic">
          Portfolio Highlights
        </span>
        <div className="headingDividerCenter">
          <span />
        </div>
      </div>

      <div className="swipeableViewsWrapper">
        <SwipeableViews
          index={currentSlide}
          onChangeIndex={handleSlideChange}
        >
          { projectItems }
        </SwipeableViews>
      </div>

      <div className="items">
        { projectItems }
      </div>

      <div className="indicatorWrapper">
        <button 
          className={(currentSlide === 0) ? "indicator filled" : "indicator"} 
          onClick={() => handleSlideChange(0)}
          name="Slide 1"
        />
        <button 
          className={(currentSlide === 1) ? "indicator filled" : "indicator"}
          onClick={() => handleSlideChange(1)} 
          name="Slide 2"
        />
        <button
          className={(currentSlide === 2) ? "indicator filled" : "indicator"}
          onClick={() => handleSlideChange(2)} 
          name="Slide 3"
        />
      </div>
      
    </section>
  );
}
