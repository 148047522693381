import React from 'react';

// Import Font Awesome
// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';

/*
  This component renders the "community" section
  of the website.
*/

export default function Community(props) {

  // Set initial state
  // (no initial state)

  return (
    <section id="community">
      <div className="background">
        <div className="top" />
        <div className="bottom" />
      </div>
      <div className="contentWrapper sidePadding">
        <h2 className="black">
          Building Community.
        </h2>
        <div className="content">
          <div className="imageContainer">
            <img 
              src={require('../../assets/images/sloth-chatting.jpg')} 
              alt="Sloth chatting with fellow creatives"
            />
          </div>   
          <div className="textContainer">
            <p>
              SlothWerks partners with businesses and organizations to improve tech equity, diversify the tech industry, and make technology more accessible.  We teach free coding classes, organize volunteers for non-profit giving, and connect local talent to their ideal network.
            </p>
            <p>
              When you work with us, your impact reaches farther than your product alone.
            </p>
          </div>
          <div className="readMoreWrapper">
            <p className="readMore">
              <span className="text">
                See what we mean!
              </span>
              <span className="icon">
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
