import React, { useState, useEffect } from 'react';

// Import Font Awesome
// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faQuoteLeft, 
  faQuoteRight 
} from '@fortawesome/free-solid-svg-icons';

/*
  This component renders the "testimonials" section
  of the website.
*/

export default function Testimonials(props) {

  // Set initial state
  const [currentItem, setCurrentItem] = useState(1);

  // Build listener for updates to currentTime
  // Will fire upon mounting as well
  useEffect(() => {
    let numberOfItems = 3;
    let newValue = 1;
    if (currentItem < numberOfItems) {
      newValue = currentItem + 1;
    }
    setTimeout(() => {
      setCurrentItem(newValue);
    }, 8000);
  }, [currentItem]);

  return (
    <section id="testimonials">
      <div className="items">
        <div className={((currentItem === 1) ? "item displayed" : "item")}>
          <div className="quoteContainer">
            <div className="leftQuote">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </div>
            <p className="quoteText center semibold">
              Rarely do you find someone with the skillset, energy, and personality that Sloth brings to the table - working with him was an absolute pleasure!
            </p>
            <div className="rightQuote">
              <FontAwesomeIcon icon={faQuoteRight} />
            </div>
          </div>
          <p className="clientText right italic">
            - Ben St. John, Authoritek LLC
          </p>
        </div>
        <div className={((currentItem === 2) ? "item displayed" : "item")}>
          <div className="quoteContainer">
            <div className="leftQuote">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </div>
            <p className="quoteText center semibold">
              While working with SlothWerks, I felt like we had a true partnership.... I especially appreciated the professionalism and precision shown during all aspects of the project.
            </p>
            <div className="rightQuote">
              <FontAwesomeIcon icon={faQuoteRight} />
            </div>
          </div>
          <p className="clientText right italic">
            - Derek Quinn, Detroit Labs
          </p>
        </div>
        <div className={((currentItem === 3) ? "item displayed" : "item")}>
          <div className="quoteContainer">
            <div className="leftQuote">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </div>
            <p className="quoteText center semibold">
            If you're looking for a master communicator who has his finger on the pulse of the tech community, with exceptionally sharp development skills, then look no further than Sloth.
            </p>
            <div className="rightQuote">
              <FontAwesomeIcon icon={faQuoteRight} />
            </div>
          </div>
          <p className="clientText right italic">
            - Travis Lark, Otterbase
          </p>
        </div>
      </div>
    </section>
  );
}
