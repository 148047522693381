import React, { useContext } from 'react';

// Import Font Awesome
// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faStore,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { 
  faLightbulb
} from '@fortawesome/free-regular-svg-icons';

// Import AppContext
import AppContext from '../../context/AppContext';

// Import React Scroll
// https://github.com/fisshy/react-scroll
import {
  animateScroll
} from 'react-scroll';

/*
  This component renders the "services" section
  of the website.
*/

export default function Services(props) {

  // Set initial state
  // (no initial state)

  // Acquire data from context
  const {
    sectionDistances,
  } = useContext(AppContext);

  // Build a function that will smoothly scroll to Contact section
  const scrollToContactSection = () => {
    animateScroll.scrollTo(sectionDistances.contact);
  }

  return (
    <section id="services">
      <div className="background">
        <div className="top" />
        <div className="bottom" />
      </div>
      <div className="contentWrapper">
        <h2 className="black center">
          <span>
            Building custom web and mobile apps.
          </span>
        </h2>
        <div className="headingDividerCenter">
          <span />
        </div>
        <div className="items">
          <div className="item">
            <span className="icon">
              <FontAwesomeIcon icon={faLightbulb} />
            </span>
            <span className="text italic">
              for start-ups and entrepreneurs
            </span>
          </div>
          <div className="item">
            <span className="icon">
              <FontAwesomeIcon icon={faStore} />
            </span>
            <span className="text italic">
              for small businesses
            </span>
          </div>
          <div className="item">
            <span className="icon">
              <FontAwesomeIcon icon={faUsers} />
            </span>
            <span className="text italic">
              for non-profits
            </span>
          </div>
        </div>
        <div className="actionButtonWrapper">
          <button
            className="actionButton"
            onClick={scrollToContactSection}
          >
            Let's talk!
          </button>
        </div>
      </div>
    </section>
  );
}
