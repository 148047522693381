import React from 'react';

// Import components
import HeaderBarMobile from './shared/components/HeaderBarMobile';
import ViewContainer from './views/ViewContainer';

/* 
  This component wraps the entire web app.  ViewContainer includes
  the various content sections; HeaderBarMobile is a fixed mobile
  header bar available in screen sizes smaller than 768px.
*/

export default function App() {

  return (
    <div className="appContainer">
      <HeaderBarMobile />
      <ViewContainer />
    </div>
  );
}
