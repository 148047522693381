import React, { useState, useEffect, useRef } from 'react';

// Import AppContext
import AppContext from '../context/AppContext';

// Import components
import HeaderBarDesktop from '../shared/components/HeaderBarDesktop';
import Splash from './splash/Splash';
import About from './about/About';
import Services from './services/Services';
import Testimonials from './testimonials/Testimonials';
import Projects from './projects/Projects';
import Products from './products/Products';
import Community from './community/Community';
import Partners from './partners/Partners';
import Contact from './contact/Contact';

/*
  This component wraps the website's content.  We're using context
  to store data related to window dimensions, current scroll position,
  and the distance to various sections.
*/

export default function ViewContainer() {

  // Set initial state
  const [windowDimensions, setWindowDimensions] = useState(null);
  const [currentPosY, setCurrentPosY] = useState(null);

  // Add listeners upon mount
  useEffect(() => {
    // Build a function to determine current scroll position
    function getCurrentPosition() {
      const position = window.pageYOffset;
      setCurrentPosY(position);
    }
    // Build function to determine window size
    function updateWindowDimensions() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // Add event listeners
    window.addEventListener("scroll", getCurrentPosition);
    window.addEventListener("resize", updateWindowDimensions);
    // Set initial values
    getCurrentPosition();
    updateWindowDimensions();
    // Remove event listeners upon dismount
    return () => {
      window.removeEventListener("scroll", getCurrentPosition);
      window.removeEventListener("resize", updateWindowDimensions);
    }
  }, []);

  // Build a ref that stores distances to the site sections
  // We're using useRef to avoice unnecessary re-renders
  const sectionDistances = useRef({
    splash: 0,
    about: 0,
    services: 0,
    testimonials: 0,
    projects: 0,
    products: 0,
    community: 0,
    partners: 0,
    contact: 0,
  });

  // Build an updater for sectionDistances
  // Takes an object of updated values as an argument;
  // will spread the new values sectionDistances
  const updateSectionDistances = (update) => {
    sectionDistances.current = {
      ...sectionDistances.current,
      ...update
    };
  };

  return (
    <AppContext.Provider value={{
      windowDimensions,
      currentPosY,
      sectionDistances: sectionDistances.current,
      updateSectionDistances
    }}>
      <main className="viewContainer">
        <HeaderBarDesktop />
        <Splash />
        <About />
        <Services />
        <Testimonials />
        <Projects />
        <Products />
        <Community />
        <Partners />
        <Contact />
      </main>
    </AppContext.Provider>
  );
}
